
























































import { Component, Vue } from 'vue-property-decorator';

import StartButton from '@/components/StartButton.vue';
import {
  dispatchCancelMatrix,
  dispatchGetMatrixStatus,
  dispatchRunMatrix,
} from '@/store/matrix/actions';
import { readMatrixFinished, readMatrixState } from '@/store/matrix/getters';
import { commitSetMatrixFinished } from '@/store/matrix/mutations';
import { commitAddNotification } from '@/store/main/mutations';
import { delay } from '@/utils';
import { IMatrixTaskCreate } from '@/interfaces/matrix';
import { filesRules } from './validators';

const MATRIX_STATUS_ACTIVE = 1;
const MATRIX_STATUS_INERVAL = 3000; // 3 seconds

@Component({
  components: {
    StartButton,
  },
})
export default class Matrix extends Vue {
  public timeOutPointer?: number = undefined;
  public files: File[] = [];
  public valid = false;

  public filesRules = filesRules;

  get gsState() {
    return readMatrixState(this.$store);
  }

  get isFinished() {
    return readMatrixFinished(this.$store);
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      // обнуляем предыдущие значения,чтобы корректно работата кнопка "Отменить"
      this.gsState.done = null;
      this.gsState.total = null;

      this.gsState.status = 1;  // чтобы сразу сделать кнопку запуска неактивной
      const createdTask: IMatrixTaskCreate = { files: this.files };
      await dispatchRunMatrix(this.$store, createdTask);
      this.checkMatrixStatus();
    }
  }

  public setFinished(value = true) {
    commitSetMatrixFinished(this.$store, value);
  }

  public async checkMatrixStatus(cancelled = false) {
    const data = await dispatchGetMatrixStatus(this.$store);
    if (data.status === MATRIX_STATUS_ACTIVE) {
      this.setFinished();
      clearInterval(this.timeOutPointer);
      this.timeOutPointer =  setTimeout(() => this.checkMatrixStatus(), MATRIX_STATUS_INERVAL);
    } else {
      this.gsState.status = null;
      if (this.isFinished) {
        const statMsg = data.total ? `выполнено ${data.done} из ${data.total}` : '';
        const msg = statMsg;
        const content = cancelled
          ? 'Матрицы: задача отменена'
          : 'Матрицы: ' + msg;
        commitAddNotification(this.$store, { content, color: 'info' });
        this.setFinished(false);
      }
    }
  }

  public async mounted() {
    this.checkMatrixStatus();
  }

  public destroyed() {
    clearInterval(this.timeOutPointer);
  }

  public async handleCancelTask() {
    this.gsState.status = null;
    // используем delay, чтобы бэк успел обновить статус в redis
    const res = await Promise.all([dispatchCancelMatrix(this.$store), delay(500)]);
    if (res) {
      clearInterval(this.timeOutPointer);
      this.checkMatrixStatus(true);
    }
  }
}
